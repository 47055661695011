import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class OrdersState {
  public itemsInCart = 3;

  public isSearchLoading = false;
  public isCategoriesOpen = true;

  public sortOptions = [{ id: 1, label: 'Vaikimisi', value: undefined }];

  public searchData = new FormGroup({
    sortBy: new FormControl(null),
    searchTerm: new FormControl(),
  });

  public activeFilters: any = {} as any;

  public filtersData = new FormGroup({
    brands: new FormControl(),
    suppliers: new FormControl(),
    importers: new FormControl(),
    gs1Segment: new FormControl(),
    countriesOfOrigin: new FormControl(),
    categories: new FormControl(),
    markings: new FormControl(),
    nutrition: new FormControl(),
    storageConditions: new FormControl(),
    searchTerm: new FormControl(),
    subBrands: new FormControl(),
    gs1Segments: new FormControl(),
    assortmentStatuses: new FormControl(),
    productAttributes: new FormControl(),
    shouldShowEndOfLifeProducts: new FormControl(),
    nutritionalClaims: new FormControl(),
  });

  public serializeSearchData() {
    const searchData = this.searchData.value;
    const filtersData = this.filtersData.value;

    const req = {
      searchTerm: searchData.searchTerm,
      countryIds: filtersData.countriesOfOrigin?.map((x: any) => x.id),
      supplierIds: filtersData.suppliers?.map((x: any) => x.id),
      brandIds: filtersData.brands?.map((x: any) => x.id),
      subBrandIds: filtersData.subBrands?.map((x: any) => x.id),
      gs1SegmentIds: filtersData.gs1Segments?.map((x: any) => x.id),
      assortmentStatuses: filtersData.assortmentStatuses,
      productAttributeIds: filtersData.productAttributes?.map((x: any) => x.id),
      shouldShowEndOfLifeProducts: filtersData.shouldShowEndOfLifeProducts,
      nutritionalClaims: filtersData.nutritionalClaims,
    };

    return Object.fromEntries(
      Object.entries(req).filter(([_, v]) => v != null),
    );
  }

  public onActiveFilterClick(key, index) {
    const newValues = this.activeFilters[key]?.filter((x, i) => i !== index);
    this.filtersData.get(key).setValue(newValues);
  }
}
